/**
 *
 * ChangeEmail
 *
 *
 */

import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { navigate } from 'gatsby';

import Link from 'components/Elements/Link';
import { logout } from 'actions/auth';

import { ACCOUNT_ROUTE, CHANGE_PASSWORD_ROUTE } from 'utils/routes';
import { updateMyEmail } from 'utils/api/users';
import { sendConfirmAccountLink } from 'utils/api/auth';
import { toastSuccess } from 'utils/toastify';

import ChangeEmailFormik from './ChangeEmailFormik';

const ChangeEmail = ({ dispatch }) => (
  <Container>
    <StyledLink to="/account" primary>
      Back to Account
    </StyledLink>
    <Title>Change Email</Title>
    <ChangeEmailFormik
      initialValues={{
        email: '',
        newEmail: '',
        newReEmail: '',
      }}
      onSubmit={async (values, { setSubmitting, setFieldError }) => {
        try {
          const data = {
            email: values.email,
            newEmail: values.newEmail,
          };
          const res = await updateMyEmail(data);
          if (res) {
            const emailRes = await sendConfirmAccountLink(values.newEmail);
            toastSuccess(
              'Successfully updated your email! Please check your new email for a confirmation link!',
            );
            dispatch(logout());
            navigate('/sign-in', { state: { email: values.newEmail } });
          }
          setSubmitting(false);
        } catch (err) {
          if (err.code && err.code === 422) {
            setFieldError(err.location, err.message);
          }
          setSubmitting(false);
        }
      }}
    />
  </Container>
);

ChangeEmail.propTypes = {
  dispatch: PropTypes.func.isRequired,
};

export default connect()(ChangeEmail);

const Container = styled.div`
  ${({ theme }) => theme.styles.grow}
  color: ${({ theme }) => theme.colors.Primary};
  max-width: 500px;
  margin: 100px auto 0;
  padding: 15px;
  border-radius: 3px;
`;

const StyledLink = styled(Link)`
  font-size: 1.6em;
  margin: 0px 15px;
  margin-bottom: 30px;
  position: relative;
  &:before {
    content: '';
    height: 10px;
    width: 10px;
    border-left: 3px solid ${({ theme }) => theme.colors.Primary};
    border-top: 3px solid ${({ theme }) => theme.colors.Primary};
    position: absolute;
    left: -20px;
    top: 2px;
    transform: rotate(-45deg);
  }
`;

const Title = styled.h2`
  margin-left: 15px;
`;
