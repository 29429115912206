/**
 *
 * Change Email Page
 *
 *
 */

import React, { useEffect, memo } from 'react';
import styled, { ThemeProvider } from 'styled-components';
import { connect } from 'react-redux';
import { compose } from 'redux';
import PropTypes from 'prop-types';

import SEO from 'components/seo';
import ChangeEmail from 'containers/Auth/ChangeEmailPass/ChangeEmail';
import theme from 'utils/theme';

const ChangeEmailPage = ({ user, navigate }) => {
  useEffect(() => {
    if (!user) {
      navigate('/');
    }
  }, [user]);

  return (
    <ThemeProvider theme={theme}>
      <Container>
        <SEO title="Change Email Page" />
        <ChangeEmail />
      </Container>
    </ThemeProvider>
  );
};

ChangeEmailPage.propTypes = {
  user: PropTypes.object.isRequired,
  navigate: PropTypes.func.isRequired,
};

const mapStateToProps = ({ user, ripspots }) => ({ user, ripspots });
const withConnect = connect(mapStateToProps);
export default compose(memo, withConnect)(ChangeEmailPage);

const Container = styled.div`
  display: flex;
`;
