/**
 *
 * Validators for Change Email Formik
 *
 *
 */

export default values => {
  const errors = {};

  switch (true) {
    case !values.email:
      errors.email = 'Required';
      break;
    case !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email):
      errors.email = 'Not a valid email.';
      break;
    case !values.newEmail:
      errors.newEmail = 'Required';
      break;
    case !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.newEmail):
      errors.newEmail = 'Not a valid email.';
      break;
    case values.email === values.newEmail:
      errors.newEmail = 'New email must be different';
      break;
    case !values.newReEmail:
      errors.newReEmail = 'Required';
      break;
    case values.newReEmail !== values.newEmail:
      errors.newReEmail = 'New emails must match';
      break;
    default:
  }

  return errors;
};
